import Auth from '@aws-amplify/auth';

import {
  cognitoLogin,
  cognitoGoogleLogin,
  cognitoLogout,
  cognitoJumpCloudLogin,
} from 'components/Auth/auth-cognito.service';

export const createUserSession = async () => {
  const session = await Auth.currentSession();
  const jwt = session.getIdToken().getJwtToken();
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return fetch('/api/charon/sessions', options);
};

export const hasUserSession = async () => {
  const response = await fetch('/api/charon/sessions');

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const deleteUserSession = async () => {
  const options = {
    method: 'DELETE',
    credentials: 'same-origin' as RequestCredentials,
  };

  return fetch('/api/charon/sessions', options);
};
export const deleteAdminSession = async () => {
  const options = {
    method: 'DELETE',
    credentials: 'same-origin' as RequestCredentials,
  };

  return fetch('/api/charon/sessions/internal', options);
};

export const createAdminSession = async () => {
  const session = await Auth.currentSession();
  const jwt = session.getIdToken().getJwtToken();
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return fetch('/api/charon/sessions/internal', options);
};

export const login = async (email: string, password: string) => {
  return await cognitoLogin(email, password);
};

export const googleLogin = async () => {
  await cognitoGoogleLogin();
};

export const JumpCloudLogin = async () => {
  await cognitoJumpCloudLogin();
};

export const logout = async () => {
  await Promise.all([
    cognitoLogout(),
    deleteUserSession(),
    deleteAdminSession(),
  ]);
};
