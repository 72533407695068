import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: theme.spacing.unit * 5,
      paddingLeft: theme.spacing.unit * 5,
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 1120,
      width: '100%',
      margin: 'auto',
      minHeight: 64,
    },
    link: {
      textDecoration: 'none',
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
    },
    logo: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.50)',
    },
  });

export default styles;
