import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import qs from 'qs';
import { datadogRum } from '@datadog/browser-rum';

const SOVRN_EMAIL = 'mailto:publishersupport@sovrn.com';
const SOVRN_CONTACT = 'https://knowledge.sovrn.com/kb-tickets/new';

interface Props {
  classes: any;
  location: any;
  confirmed: boolean;
  checking: boolean;
}

const VerificationStatus = ({ checking, confirmed, classes, location }: Props) => {
  const { token: _token, email: _email, ...query } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const queryStr = qs.stringify(query);

  if (checking) {
    return (<span className={classes.checking}><CircularProgress color="secondary"/></span>);
  }

  if (confirmed) {
    datadogRum.addAction('email confirmed');
  }

  return confirmed ? (
    <>
      <Typography variant="h4">Success</Typography>
      <Typography variant="body2" className={classes.message}>
        Thank you for confirming your email address. You will be redirected to the sign-in page momentarily. If you're
        not redirected, please visit the&nbsp;
        <Link to={`/signin${queryStr ? `?${queryStr}` : ''}`} className={classes.link}>
          sign-in page
        </Link>
        .
      </Typography>
    </>
    ) : (
    <>
      <Typography variant="h4">Confirmation Error</Typography>
      <Typography variant="body2" className={classes.message}>
        Sorry for the inconvenience. We were unable to confirm your email address. Please contact our&nbsp;
        <a href={SOVRN_EMAIL} className={classes.link}>
          support team
        </a>
        &nbsp;for assistance.
      </Typography>
      <Button variant="contained" type="submit" color="secondary" className={classes.button}>
        <a href={SOVRN_CONTACT} className={classes.whiteLink}>
          Support
        </a>
      </Button>
    </>
  );
};

export default withStyles(styles)(VerificationStatus);
