import * as React from 'react';
import { forgotPassword } from '../../Auth/auth.service';
import CardContainer from '../../CardContainer/CardContainer';
import { ErrorToast } from '../../ToastMessage/ToastMessage';
import Confirm from '../../Signup/Confirm/Confirm';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import { FormikActions } from 'formik';

export interface ForgotPasswordState {
  serverError: string | null;
  email: string;
  step: FORGOT_PASSWORD_PROGRESS;
  disableSendEmail: boolean;
  isSubmitting: boolean;
}

export enum FORGOT_PASSWORD_PROGRESS {
  ENTER_INFO,
  RESET_PASSWORD_EMAIL,
}

export const DEFAULT_ERROR_MESSAGE =
  'Oops, something went wrong. Please check your network connection and try again.';
const INVALID_PARAMETER_ERROR_NAME = 'InvalidParameterException';
const INVALID_PARAMETER_ERROR_MESSAGE =
  'Cannot reset password for the user as there is no verified email.';

export class ForgotPassword extends React.Component<any, ForgotPasswordState> {
  constructor(props: any) {
    super(props);
    this.state = {
      serverError: null,
      email: '',
      step: FORGOT_PASSWORD_PROGRESS.ENTER_INFO,
      disableSendEmail: false,
      isSubmitting: false,
    };
  }

  public handleSubmission = async (
    formValues: any,
    actions: FormikActions<any>,
  ) => {
    try {
      this.setState(formValues);
      await this.handleSendEmail();
      actions.setSubmitting(false);
      actions.resetForm();
      this.setState({ disableSendEmail: false });
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  /*
    Used InvalidParameterException name to catch an error with unverified email.
    It may have conflicts with other errors.
    See https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
   */
  private getServerErrorMessage = (exception: any) => {
    return exception && exception.name === INVALID_PARAMETER_ERROR_NAME
      ? INVALID_PARAMETER_ERROR_MESSAGE
      : DEFAULT_ERROR_MESSAGE;
  };

  public handleSendEmail = async () => {
    const { step, email } = this.state;
    this.setState({ isSubmitting: true });

    try {
      this.setState({ serverError: null, disableSendEmail: true });
      await forgotPassword(email);
      if (step === FORGOT_PASSWORD_PROGRESS.ENTER_INFO) {
        this.handleStepper(FORGOT_PASSWORD_PROGRESS.RESET_PASSWORD_EMAIL);
      }
    } catch (exception) {
      this.setState({
        serverError: this.getServerErrorMessage(exception),
      });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  public handleStepper = (step: number) => {
    this.setState({ step });
  };

  public renderStep = () => {
    const { step, disableSendEmail } = this.state;

    switch (step) {
      case FORGOT_PASSWORD_PROGRESS.RESET_PASSWORD_EMAIL:
        return (
          <Confirm
            handleSendEmail={this.handleSendEmail}
            disableSendEmail={disableSendEmail}
            title="Check Your Email"
            message="We sent you instructions on how to reset your password."
          />
        );
      case FORGOT_PASSWORD_PROGRESS.ENTER_INFO:
      default:
        return (
          <ForgotPasswordForm
            handleSubmission={this.handleSubmission}
            isSubmitting={this.state.isSubmitting}
          />
        );
    }
  };

  public render() {
    return (
      <>
        {this.state.serverError ? (
          <ErrorToast>{this.state.serverError}</ErrorToast>
        ) : null}
        <CardContainer>{this.renderStep()}</CardContainer>
      </>
    );
  }
}

export default ForgotPassword;
