import * as React from 'react';
import {
  CardContent,
  Typography,
  Button,
  withStyles,
  Link,
} from '@material-ui/core';

import styles from './styles';
import { Formik, Form, Field } from 'formik';

import CardContainer from 'components/CardContainer/CardContainer';
import MfaField, { schemaMfa } from 'components/FormElements/MfaField/MfaField';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';

const CompleteMfa = ({
  classes,
  handleConfirmSignin,
  errMsg,
}: any) => {
  return (
    <>
      {errMsg && <ErrorToast id="mfa-verify-error">{errMsg}</ErrorToast>}
      <CardContainer>
        <CardContent>
          <Formik
            onSubmit={values => handleConfirmSignin(values)}
            initialValues={{
              mfa: '',
            }}
            validationSchema={schemaMfa}
            render={({ isValid, isValidating }) => (
              <Form>
                <Typography variant="h4" className={classes.title}>
                  Enter verification code
                </Typography>
                <Typography>
                  Verify your identity by entering a code from your authenticator app.
                </Typography>
                <span className={classes.mfaField}>
                  <Field
                    id="mfa"
                    name="mfa"
                    label="Verification code"
                    component={MfaField}
                  />
                </span>

                <Button
                  id="finishButton"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  disabled={!isValid || isValidating}
                >
                  Authenticate
                </Button>
                <span className={classes.spanToSupport}>
                  <Link
                    className={classes.linkToSupport}
                    href="https://knowledge.sovrn.com/s/contactsupport"
                    target="_blank"
                  >
                    <Typography
                      variant="body2"
                      align="center"
                      className={classes.toSupport}
                    >
                      Lost your multi-factor authentication device?
                    </Typography>
                  </Link>
                </span>
              </Form>
            )}
          />
        </CardContent>
      </CardContainer>
    </>
  );
};
export default withStyles(styles)(CompleteMfa);
