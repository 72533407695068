import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      marginTop: theme.spacing.unit * 4,
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
    },
    privacyPolicyLink: {
      fontWeight: 700,
    },
    privacyPolicy: {
      lineHeight: '14px',
      marginTop: theme.spacing.unit * 5,
      minHeight: '42px',
    },
  });

export default styles;
