import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, FormikProps, FormikActions, Field } from 'formik';
import * as yup from 'yup';
import {
  Typography,
  CardContent,
  CircularProgress,
  Button,
  withStyles,
  Link,
} from '@material-ui/core';

import { register, SIGNUP_STATUS } from 'components/Auth/auth.service';
import CardContainer from 'components/CardContainer/CardContainer';
import EmailField, {
  emailValidation,
} from 'components/FormElements/EmailField/EmailField';
import PasswordField, {
  passwordValidation,
} from 'components/FormElements/PasswordField/PasswordField';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import GoogleButton from 'components/FormElements/GoogleButton/GoogleButton';
import { persistRedirect } from 'components/AbsoluteRedirect/redirectTargets.service';
import styles from './styles';
import qs from 'qs';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  classes?: any;
  location: any;
  history?: any;
}

interface State {
  error: JSX.Element | null;
  password: string;
  email: string;
  confirmEmail: boolean;
}

export const ACCOUNT_EXISTS = (
  <>
    This email is already registered. <br></br>
    Please{' '}
    <a style={{ color: 'white' }} href="/account/signin">
      sign in
    </a>{' '}
    with your existing Sovrn credentials.
  </>
);
export const SERVER_ERROR = (
  <>Sorry, an error occurred while creating your account. Please try again.</>
);

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export class Signup extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      password: '',
      email: '',
      confirmEmail: false,
    };
  }

  public handleSubmission = async (
    formValues: any,
    actions: FormikActions<any>,
  ) => {
    this.setState(formValues);
    const { password, email } = this.state;

    try {
      await register(email, password);
      persistRedirect();
      this.setState({ confirmEmail: true });
      datadogRum.addAction('sign up submission');
      // Navigate to confirm page
    } catch (exception: any) {
      switch (exception.code) {
        case SIGNUP_STATUS.USERNAME_EXISTS:
          this.setState({ error: ACCOUNT_EXISTS });
          break;
        default:
          this.setState({ error: SERVER_ERROR });
          break;
      }
    }

    actions.setSubmitting(false);
  };

  public renderStep = () => {
    const { email, confirmEmail } = this.state;
    const { classes, location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const params = new URLSearchParams(location.search);
    if (params.get('d') == 'com') {
      params.delete('d');
      this.props.history.replace({ search: params.toString() });
    }

    if (confirmEmail) {
      const confirmQuery = { email, ...query };
      return <Redirect to={`/signup/confirm?${qs.stringify(confirmQuery)}`} />;
    } else {
      return (
        <CardContent className={classes.cardContent}>
          <Typography variant="overline">
            Earnings. Analytics. Expertise.
          </Typography>
          <Typography variant="h4" id="create-free-account">
            Create a free account
          </Typography>
          <Formik
            onSubmit={this.handleSubmission}
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            render={(props: FormikProps<any>) => (
              <Form>
                <GoogleButton />
                <Field
                  type="email"
                  autoComplete="email"
                  label="Email"
                  margin="normal"
                  name="email"
                  id="email-input"
                  component={EmailField}
                />
                <Field
                  id="password"
                  name="password"
                  component={PasswordField}
                />
                <Typography className={classes.privacyPolicy} variant="caption">
                  By creating an account, you agree to the Sovrn{' '}
                  <Link
                    className={classes.privacyPolicyLink}
                    href="https://www.sovrn.com/legal/privacy-policy/"
                    target="_blank"
                    color="secondary"
                    underline="none"
                  >
                    privacy policy
                  </Link>
                  . You also agree that Sovrn can use your email address to let
                  you know about potential services.
                </Typography>
                <Button
                  id="finish-button"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  disabled={!props.isValid || props.isSubmitting}
                  className={classes.button}
                >
                  {props.isSubmitting ? (
                    <CircularProgress size={22} color="secondary" />
                  ) : (
                    `Finish`
                  )}
                </Button>
              </Form>
            )}
          />
        </CardContent>
      );
    }
  };

  public render() {
    return (
      <>
        {this.state.error && (
          <ErrorToast id="sign-in-error">{this.state.error}</ErrorToast>
        )}
        <CardContainer>{this.renderStep()}</CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(Signup);
