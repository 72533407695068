import React from 'react';
import { Typography, CardContent, withStyles } from '@material-ui/core';

import styles from './styles';
import CardContainer from '../CardContainer/CardContainer';
import qs from 'qs';

interface BannerProps {
  location: any;
  hidden?: boolean;
  classes?: any;
}

const Banner = ({ location, hidden = false, classes }: BannerProps) => {
  const signinComMessage = (
    <span className={classes.boldText}>
      Thanks for creating your Sovrn //Commerce account. Now it’s time to start
      earning. Sign in now and start creating links!
    </span>
  );
  const signupComMessage = (
    <>
      <span className={classes.boldText}>Get started for free.</span>
      Sovrn //Commerce is affiliate marketing made easy. Get ready to start
      turning your clicks into cash.
    </>
  );

  const { pathname, search } = location;
  const { d: origin } = qs.parse(search, { ignoreQueryPrefix: true });

  return (
    <div
      className={classes.root}
      style={{ display: hidden ? 'none' : 'block' }}
    >
      <CardContainer>
        <CardContent>
          <Typography
            id="header-banner"
            className={classes.typography}
            variant="body2"
            align="center"
          >
            {origin === 'com' || origin === 'comq'
              ? (pathname === '/account/signin' && signinComMessage) ||
                (pathname === '/account/signup' && signupComMessage)
              : null}
          </Typography>
        </CardContent>
      </CardContainer>
    </div>
  );
};

export default withStyles(styles)(Banner);
