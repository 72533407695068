import React from 'react';
import { Typography, CardContent, Button, withStyles } from '@material-ui/core';
import styles from './styles';
import CardContainer from 'components/CardContainer/CardContainer';
import { JumpCloudLogin } from 'components/Auth/auth-session.service';

export type SSOProps = {
  classes?: any;
};

const SSO = ({ classes }: SSOProps) => {
  return (
    <CardContainer>
      <CardContent>
        <Typography variant={'overline'}>sovrn admin manager</Typography>
        <Typography variant={'h4'}>Single sign on</Typography>
        <Typography variant={'body1'} className={classes.bodyCopy}>
          Authenticate your account by logging into the single sign on provider
          for Sovrn.
        </Typography>
        <Button
          className={classes.button}
          variant={'contained'}
          color={'secondary'}
          onClick={JumpCloudLogin}
        >
          Continue
        </Button>
      </CardContent>
    </CardContainer>
  );
};

export default withStyles(styles)(SSO);
