/* eslint-disable no-console */
import { getIdToken, LOGIN_STATUS } from './auth-cognito.service';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

const isAdminToken = (idToken: CognitoIdToken) =>
  idToken.payload.email?.endsWith('sovrn.com');

export const commerceServers = {
  //for commerce sovrn domain
  qa: 'https://commerce-qa.sovrn.com',
  stage: 'https://commerce.sovrn.com',
  prod: 'https://commerce.sovrn.com',
  local: 'https://commerce-qa.sovrn.com',
};

export const viglinkServers = {
  qa: 'https://auth.qa.viglink.com',
  stage: 'https://auth.viglink.com',
  prod: 'https://auth.viglink.com',
  local: 'https://auth.qa.viglink.com',
};

export const getCommerceServer = () => {
  if (
    window.location.hostname.startsWith('platform-qa')
  ) {
    return commerceServers.qa;
  }
  if (
    window.location.hostname.startsWith('platform-stg')
  ) {
    return commerceServers.stage;
  }
  if (
    window.location.hostname.startsWith('platform.')
  ) {
    return commerceServers.prod;
  }
  return commerceServers.local;
};

export const getViglinkServer = () => {
  if (
    window.location.hostname.startsWith('platform-qa')
  ) {
    return viglinkServers.qa;
  }
  if (
    window.location.hostname.startsWith('platform-stg')
  ) {
    return viglinkServers.stage;
  }
  if (
    window.location.hostname.startsWith('platform.')
  ) {
    return viglinkServers.prod;
  }
  return viglinkServers.local;
};

export const authenticateToCommerce = async () => {
  try {
    const idToken = await getIdToken();
    const isAdmin = isAdminToken(idToken);
    const jwt = idToken.getJwtToken();
    const include = 'include' as const;
    const options = {
      method: 'POST',
      body: JSON.stringify({ jwt }),
      credentials: include,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      if (isAdmin) {
        await fetch(`${getViglinkServer()}/login`, options);
      }
    } catch (e: any) {
      console.log(`Error authenticating to viglink.com: ${e.message}`);
    }

    const response = await fetch(`${getCommerceServer()}/login`, options);

    let message;
    try {
      message = await response.json();
    } catch (ignore) {}

    switch (response.status) {
      case 200:
        return Promise.resolve(true);
      case 401:
      case 422:
        return Promise.reject({
          code: LOGIN_STATUS.COMMERCE_LOGIN_FAILED,
          message,
        });
      default:
        return Promise.reject({
          code: LOGIN_STATUS.COMMERCE_LOGIN_ERROR,
          message,
        });
    }
  } catch (ignore) {
    return Promise.reject({ code: LOGIN_STATUS.COMMERCE_LOGIN_ERROR });
  }
};

export const hasCommerceSession = async () => {
  const response = await fetch('/api/commerce/platform/campaigns');

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const logoutCommerce = async () => {
  const include = 'include' as const;
  let isAdmin = false;
  try {
    isAdmin = isAdminToken(await getIdToken());
  } catch (ignore) {}
  const options = {
    method: 'POST',
    credentials: include,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await fetch(`${getCommerceServer()}/logout`, options);
    if (isAdmin) {
      await fetch(`${getViglinkServer()}/logout`, options);
    }
    return Promise.resolve(result.status === 200);
  } catch (e) {
    return Promise.resolve(false);
  }
};
