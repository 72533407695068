import * as React from 'react';
import {
  Button,
  Divider,
  Typography,
  withStyles,
  createStyles,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import qs from 'qs';

import GoogleLogo from '../../../assets/google.svg';
import { googleLogin } from 'components/Auth/auth-session.service';

const styles = (theme: Theme) =>
  createStyles({
    googleButton: {
      width: '100%',
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
    },
    googleLogo: {
      marginRight: theme.spacing.unit * 2,
    },
    orDivider: {
      border: '0',
      height: '10px',
      backgroundColor: 'white',
    },
    orDividerText: {
      backgroundColor: 'white',
      padding: theme.spacing.unit * 3,
      color: 'rgba(40, 40, 40, 0.32)',
      fontWeight: 'bold',
    },
    divider: {
      marginBottom: theme.spacing.unit * 2,
    },
  });

interface GoogleButtonProps {
  classes?: any;
}
export const GoogleButton = (props: GoogleButtonProps) => {
  React.useEffect(() => {
    if (!!qs.parse(window.location.search).state) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, []);
  const [loading, setLoading] = React.useState(false);
  const handleLogin = () => {
    setLoading(true);
    googleLogin();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        className={props.classes.googleButton}
        onClick={handleLogin}
        id="google-sign-in-button"
      >
        {loading ? (
          <CircularProgress size={24} color="secondary" />
        ) : (
          <>
            <GoogleLogo className={props.classes.googleLogo} /> Continue with
            Google
          </>
        )}
      </Button>
      <Typography className={props.classes.orDivider} align="center">
        <span className={props.classes.orDividerText}>OR</span>
      </Typography>
      <Divider className={props.classes.signInDivider} />
    </>
  );
};

export default withStyles(styles)(GoogleButton);
