import { createTheme } from '@sovrn/mui-theme';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export default createTheme({
  breakpoints: breakpoints,
  typography: {
    h4: {
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '26px',
      },
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          // Override Chrome's blue background w/ autocomplete. It's hacky, but currently the only solution.
          WebkitBoxShadow: '0 0 0px 1000px white inset !important',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#ffffff',
    },
  },
});
