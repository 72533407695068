import * as React from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  withStyles,
  InputAdornment,
  IconButton,
  WithStyles,
  Typography,
} from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FieldProps, getIn, ErrorMessage } from 'formik';
import * as yup from 'yup';
import styles from './styles';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface PasswordFieldProps
  extends FieldProps,
    WithStyles,
    Omit<InputProps, 'classes' | 'error' | 'name' | 'onChange' | 'value'> {}

export const fieldToPasswordInput = ({
  field,
  form,
  disabled = false,
  ...props
}: PasswordFieldProps): InputProps & {
  label?: 'string';
} => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const { classes, ...otherProps } = props;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...otherProps,
    ...field,
    error: showError,
    disabled: isSubmitting || disabled,
  };
};

interface PasswordFieldState {
  showPassword: boolean;
}

export const passwordValidation = yup
  .string()
  .strict(true)
  .required('Please enter a valid password')
  .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
  .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
  .matches(
    /^[a-zA-Z0-9\^$*.\[\]{}\(\)?\-"!@#%&\/,><\\':;|_~`]{0,99}$/,
    'Password does not meet acceptable requirements.',
  )
  .min(8, 'Password must be at least 8 characters');

export class PasswordField extends React.Component<
  PasswordFieldProps,
  PasswordFieldState
> {
  public state: PasswordFieldState = {
    showPassword: false,
  };

  public handleClickShowPassword = () => {
    this.setState((state: PasswordFieldState) => ({
      showPassword: !state.showPassword,
    }));
  };

  public render = () => {
    const { classes } = this.props;

    return (
      <FormControl className={classes.input}>
        <InputLabel htmlFor={`${this.props.id}`}>
          {fieldToPasswordInput(this.props).label || 'Password'}
        </InputLabel>
        <Input
          {...fieldToPasswordInput(this.props)}
          type={this.state.showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                id="reveal-password-button"
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                color="secondary"
                className={classes.iconButton}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <ErrorMessage
          name={fieldToPasswordInput(this.props).name || 'password'}
        >
          {msg => (
            <Typography color="error" variant="caption">
              {msg}
            </Typography>
          )}
        </ErrorMessage>
        <Typography variant="caption">
          Minimum of 8 characters with at least 1 uppercase and 1 lowercase
        </Typography>
      </FormControl>
    );
  };
}

export default withStyles(styles)(PasswordField);
