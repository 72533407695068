import * as React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

class ErrorBoundary extends React.Component <any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    // TODO: Log errors to a logging service
    // logErrorToMyService(error, info);
    this.setState({
      error,
      errorInfo,
    });
  }

  public reload = () => {
    return window.location.reload();
  }

  public render() {
    if (this.state.error) {
      return (
        <Grid
          container
          spacing={16}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12}>
            <Typography
              component="div"
              variant="h4"
              style={{
                padding: 8,
                color: 'rgba(40, 40, 40, 0.7)',
              }}
            >
              Oops
            </Typography>
            <Typography
              style={{
                padding: 8,
                color: 'rgba(40, 40, 40, 0.87)',
              }}
              component="div"
              variant="subtitle1"
            >
              Something went wrong. Please try reloading the application.
            </Typography>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingRight: 8,
                paddingTop: 32,
              }}
            >
              <Button onClick={this.reload} color="secondary" variant="contained">Reload</Button>
            </span>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
