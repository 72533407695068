import * as React from 'react';
import { CardContent, Typography, Button, withStyles } from '@material-ui/core';
import styles from './styles';
import { Formik, Form, Field, FormikActions } from 'formik';
import * as yup from 'yup';

import CardContainer from 'components/CardContainer/CardContainer';
import PasswordField, {
  passwordValidation,
} from 'components/FormElements/PasswordField/PasswordField';

import { completeNewPassword } from 'components/Auth/auth.service';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import AbsoluteRedirect from 'components/AbsoluteRedirect/AbsoluteRedirect';

interface PropsCompletePassword {
  classes: any;
  user: any;
  tempPassword?: string;
}

interface StateCompletePassword {
  redirectToSignIn: boolean;
  serverError: boolean;
}

const schemaCompletePassword = yup.object().shape({
  password: passwordValidation,
});

export class CompletePassword extends React.Component<
  PropsCompletePassword,
  StateCompletePassword
> {
  constructor(props: PropsCompletePassword) {
    super(props);
    this.state = {
      redirectToSignIn: false,
      serverError: false,
    };
  }

  public render() {
    const { classes } = this.props;
    const { redirectToSignIn } = this.state;

    if (redirectToSignIn) {
      return (
        <AbsoluteRedirect to={`/account/signin${window.location.search}`} />
      );
    }

    return this.renderCompletePassword(classes);
  }

  public handleSubmitCompletePassword = async (
    values: any,
    actions: FormikActions<Record<string, unknown>>,
  ) => {
    try {
      if (this.props.tempPassword === values.password) {
        actions.setFieldError(
          'password',
          'Your new password cannot match the temporary password.',
        );
      } else {
        await completeNewPassword(this.props.user, values.password);
        this.setState({ serverError: false, redirectToSignIn: true });
      }
    } catch (error) {
      this.setState({ serverError: true });
    } finally {
      actions.setSubmitting(false);
    }
  };

  private renderCompletePassword(classes: any) {
    return (
      <>
        {this.state.serverError ? (
          <ErrorToast id="sign-in-error">
            We encountered a problem. Please try again, or{` `}
            <a
              href="https://knowledge.sovrn.com/kb-tickets/new"
              target="_blank"
              rel="noopener noreferrer"
              id="contact-support"
              style={{ color: 'white' }}
            >
              contact support
            </a>
            .
          </ErrorToast>
        ) : null}
        <CardContainer>
          <CardContent>
            <Formik
              onSubmit={this.handleSubmitCompletePassword}
              initialValues={{
                password: '',
                userAgreement: false,
              }}
              validationSchema={schemaCompletePassword}
              render={({ isSubmitting, isValid }) => (
                <Form>
                  <Typography variant="h4">Choose a password</Typography>

                  <span className={classes.passwordField}>
                    <Field
                      id="password"
                      name="password"
                      label="Enter a new password"
                      component={PasswordField}
                    />
                  </span>

                  <Button
                    id="finishButton"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    disabled={!isValid || isSubmitting}
                  >
                    Set Password
                  </Button>
                </Form>
              )}
            />
          </CardContent>
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(CompletePassword);
