import * as React from 'react';

import { confirmChangedEmail } from '../../Auth/auth.service';
import Verification from '../../Verification/Verification';
import qs from 'qs';

interface Props {
  location: any;
}

const ChangeEmailVerification = (props: Props) => {

  const handler = async (location: any) => {
    try {
      const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
      await confirmChangedEmail(token);
    } catch (error) {
      throw error;
    }
  };

  return <Verification
    {...props}
    handler={handler}
  />;
};

export default ChangeEmailVerification;
