import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      marginTop: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
    },
    header: {
      marginBottom: theme.spacing.unit * 3,
    },
  });

export default styles;
