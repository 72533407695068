import * as React from 'react';
import { Typography, withStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    toast: {
      textAlign: 'center',
      width: '100%',
      fontSize: '15px',
      lineSpacing: '20px',
      characterSpacing: '0.25px',
      padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2}px`,
      zIndex: 999,
      top: 0,
      left: 0,
      borderTopRightRadius: theme.spacing.unit,
      borderTopLeftRadius: theme.spacing.unit,
    },
  });

interface ToastProps {
  children?: any;
  classes?: any;
  id?: string;
}

interface SpecificToastProps extends ToastProps {
  textColor: string;
  backgroundColor: string;
}

const ToastMessage = (props: SpecificToastProps) => (
  <Typography
    variant="h6"
    className={props.classes.toast}
    style={{ backgroundColor: props.backgroundColor, color: props.textColor }}
    id={props.id}
  >
    {props.children}
  </Typography>
);

export const ErrorToast = withStyles(styles)((props: ToastProps) => (
  <ToastMessage backgroundColor="#b00020" textColor="#ffffff" {...props} />
));
export const InfoToast = withStyles(styles)((props: ToastProps) => (
  <ToastMessage backgroundColor="#ffe94f" textColor="0#44412d" {...props} />
));

export default withStyles(styles)(ToastMessage);
