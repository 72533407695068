import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing.unit * 2,
    },
    button: {
      width: '100%',
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
      marginBottom: theme.spacing.unit * 2,
    },
    bodyCopy: {
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 5,
    }
  });

export default styles;
