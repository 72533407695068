import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
    },
    passwordField: {
      display: 'block',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 1,
    },
    containerSpan: {
      marginLeft: theme.spacing.unit * -1.5,
    },
  });

export default styles;
