import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    footer: {
      // add extra margin to account for iOS url drawer
      marginBottom: theme.spacing.unit * 8,
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
      },
    },
    copyright: {
      color: 'rgba(0, 0, 0, 0.50)',
    },
    icon: {
      top: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit,
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.50)',
      position: 'relative',
    },
    link: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.50)',
    }
  });

export default styles;
