import React from 'react';
import {
  Typography,
  withStyles,
  Grid,
} from '@material-ui/core';
import { Copyright } from '@material-ui/icons';
import styles from './styles';

interface Props {
  hidden?: boolean;

  classes?: any;
}
export class Footer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { hidden = false, classes } = this.props;
    const currentYear = new Date().getFullYear();

    const isAdminAuth = location?.pathname.includes("admin")

    return (
      <div style={{ display: hidden ? 'none' : 'normal' }}>

        <Grid container justify={isAdminAuth ? "flex-end" :"space-around"} className={classes.footer}>
          <Typography
            id="copyright"
            variant="caption"
            className={classes.copyright}
          >
            <Copyright className={classes.icon} />
            {currentYear} Sovrn |{` `}
            {!isAdminAuth && (
              <>
                <a
                  href="https://www.sovrn.com/legal/"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="privacy-link"
                  className={classes.link}
                >
                  Legal
                </a>
                {` `}|{` `}
                <a
                  href="https://www.sovrn.com/legal/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="privacy-link"
                  className={classes.link}
                >
                  Privacy
                </a>
                {` `}|{` `}
              </>
            )}

            <a
              href="https://status.sovrn.com/"
              target="_blank"
              rel="noopener noreferrer"
              id="status-link"
              className={classes.link}
            >
              Status
            </a>
            {!isAdminAuth && (
              <>
                {` `}|{` `}
                <a
                  href="https://knowledge.sovrn.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="help-button"
                  className={classes.link}
                >
                  Help
                </a>
              </>
            )}
          </Typography>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);

