import * as React from 'react';
import { withStyles } from '@material-ui/core';
import qs from 'qs';

import { ErrorToast } from '../../ToastMessage/ToastMessage';
import CardContainer from '../../CardContainer/CardContainer';
import styles from './styles';
import { resendRegistrationConfirmation } from '../../Auth/auth.service';
import Confirm from './Confirm';

interface Props {
  classes: any;
  location: any;
}

interface State {
  errorComponent: any;
  email: string;
  disableSendEmail: boolean;
}

export const ResendError = () => (
  <ErrorToast id="resend-confirmation-error-msg">
    We encountered a problem. Please try again, or{` `}
    <a
      href="https://knowledge.sovrn.com/kb-tickets/new"
      target="_blank"
      rel="noopener noreferrer"
      id="contact-support"
      style={{ color: 'white' }}
    >
      contact support
    </a>
    .
  </ErrorToast>
);

export class ConfirmationEmail extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      errorComponent: null,
      email: '',
      disableSendEmail: false,
    };
  }

  public componentDidMount = () => {
    const { email } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState({ email: decodeURIComponent(email) });
  };

  public handleSendEmail = async () => {
    const { email } = this.state;

    try {
      this.setState({ disableSendEmail: true });
      await resendRegistrationConfirmation(email);
    } catch (err) {
      this.setState({ errorComponent: ResendError });
    }
  };

  public renderError = () => {
    if (this.state.errorComponent === null) {
      return null;
    } else {
      const ErrorComponent = this.state.errorComponent;
      return <ErrorComponent />;
    }
  };

  public render() {
    const { disableSendEmail } = this.state;
    return (
      <>
        {this.renderError()}
        <CardContainer>
          <Confirm
            handleSendEmail={this.handleSendEmail}
            disableSendEmail={disableSendEmail}
            title="Verify Your Account"
            message="A verification link has been sent to your email address."
          />
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(ConfirmationEmail);
