type HttpMethod = 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';

const getHeaders = () => ({
  credentials: 'include',
  headers: { 'Content-Type': 'application/json' },
});

const request = (method: HttpMethod, url: string, options?: RequestInit) =>
  fetch(url, {
    method,
    ...getHeaders(),
    ...options,
  } as RequestInit);

export const http = {
  get: (url: string, options?: RequestInit) => request('GET', url, options),
  patch: (url: string, options?: RequestInit) => request('PATCH', url, options),
  post: (url: string, options?: RequestInit) => request('POST', url, options),
  put: (url: string, options?: RequestInit) => request('PUT', url, options),
  delete: (url: string, options?: RequestInit) =>
    request('DELETE', url, options),
};

export const checkResponse = (res: Response, errorMsg = '') => {
  if (!res.ok) {
    throw new Error(errorMsg || res.statusText);
  }

  return res.json();
};