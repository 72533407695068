import * as React from 'react';
import { Paper, withStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      padding: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 3,
      borderRadius: theme.spacing.unit / 2,
    },
});

interface Props {
  children: any;
  classes: any;
}

const CardContainer = (props: Props) => (
  <Paper className={props.classes.container}>
    {props.children}
  </Paper>
);

export default withStyles(styles)(CardContainer);
