import qs from 'qs';

const validDomains = {
  com: 'commerce.sovrn.com',
  comq: 'commerce-qa.sovrn.com',
  platform: 'platform.sovrn.com',
  platformq: 'platform-qa.sovrn.com',
  platforms: 'platform-stg.sovrn.com',
};
type validDomainStrings =
  | 'com'
  | 'comq'
  | 'platform'
  | 'platformq'
  | 'platforms';

export function clearPersistedRedirect() {
  localStorage.removeItem('signup-redirect-target');
}

export function getTargetFromGoogleRedirect(path: string) {
  const parsePath = qs.parse(path);
  let domain = window.location.hostname;
  const domainKey = parsePath.d;
  if (domainKey && Object.keys(validDomains).includes(domainKey)) {
    domain = validDomains[domainKey as validDomainStrings];
  }
  const redirect = {
    domain,
    path: parsePath.path || '',
    fqdn: '',
  };
  redirect.fqdn = `https://${redirect.domain}/${redirect.path}`;

  return redirect;
}

export function getRedirectTarget(initialRender = false) {
  if (initialRender) {
    clearPersistedRedirect(); // here we need to clear the localStorage to make sure we are setting the correct path
  }

  const query = new URLSearchParams(window.location.search);
  const domainKey = query.get('d');
  let domain = window.location.hostname;
  let path = query.get('path') || '';

  if (domainKey && Object.keys(validDomains).includes(domainKey)) {
    domain = validDomains[domainKey as validDomainStrings];
  }

  if (!domainKey) {
    const persist = localStorage.getItem('signup-redirect-target');
    if (persist) {
      const parsed = JSON.parse(persist);
      domain = parsed.domain;
      path = parsed.path;
    }
  }
  const redirect = {
    domain,
    path,
    fqdn: '',
  };

  redirect.fqdn = `https://${redirect.domain}/${redirect.path}`;

  return redirect;
}

export function persistRedirect(initialRender = false) {
  localStorage.setItem(
    'signup-redirect-target',
    JSON.stringify(getRedirectTarget(initialRender)),
  );
}
