import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    bodyWrapper: {
      minHeight: 'calc(100vh - 80px)', // trim header height
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        minHeight: 'calc(100vh - 64px)', // trim header height - less margin
        maxWidth: 1120,
        margin: 'auto',
      },
    },
    contentWrapper: {
      paddingBottom: theme.spacing.unit * 4,
      marginLeft: 16,
      marginRight: 16,
    },
    content: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 448,
      },
      [theme.breakpoints.up('lg')]: {
        width: 448,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }
    },
    backgroundColor: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      backgroundColor: '#f7f7f7',
      zIndex: -999,
    },
    backgroundImg: {
      zIndex: -999,
      position: 'fixed',
      bottom: 0,
      right: 0,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing.unit * 2,
      [theme.breakpoints.up('lg')]: {
        width: 448,
        marginTop: theme.spacing.unit * 3,
      },
    },
  });

export default styles;
