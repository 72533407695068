const PRODUCTION_HOST_INTERNAL = 'meridian.awscloud.lijit.com';
const PRODUCTION_HOST_PLATFORM = 'platform.sovrn.com';
const STAGING_SUBDOMAIN_PLATFORM = 'platform-stg';
const PRODUCTION_HOST_ADMIN = 'admin.sovrn.com';
const STAGING_SUBDOMAIN_ADMIN = 'admin-stg';
const PROD_POOL_DOMAIN = 'auth.sovrn.com';
const QA_POOL_DOMAIN = 'auth-qa.sovrn.com';
const QA_POOL_ID = 'us-east-1_X2oGxrBCJ';
const QA_WEB_CLIENT_ID = '7ue5upp4a3b7958rfrul28qtdq';
const PROD_POOL_ID = 'us-east-1_ocpudJiNh';
const PROD_WEB_CLIENT_ID = '3d471trt2phch15cqf033lb2t3';

let poolDomain = '';
let poolId = '';
let webClientId = '';

function getAuthConfig(win: Window) {
  const origin = win.location.origin.toLowerCase();
  if (
    origin.search(PRODUCTION_HOST_INTERNAL) >= 0 ||
    origin.search(PRODUCTION_HOST_PLATFORM) >= 0 ||
    origin.search(PRODUCTION_HOST_ADMIN) >= 0 ||
    origin.search(STAGING_SUBDOMAIN_PLATFORM) >= 0 ||
    origin.search(STAGING_SUBDOMAIN_ADMIN) >= 0
  ) {
    poolId = PROD_POOL_ID;
    poolDomain = PROD_POOL_DOMAIN;
    webClientId = PROD_WEB_CLIENT_ID;
  } else {
    poolDomain = QA_POOL_DOMAIN;
    poolId = QA_POOL_ID;
    webClientId = QA_WEB_CLIENT_ID;
  }

  const oauth = {
    domain: poolDomain,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: `${origin}/account/signin`,
    redirectSignOut: `${origin}/account/signin`,
    responseType: 'code',
  };
  const clientMetadata = {
    origin,
  };
  const authConfig = {
    oauth,
    region: 'us-east-1',
    userPoolId: poolId,
    userPoolWebClientId: webClientId,
    clientMetadata,
  };
  return {
    Auth: authConfig,
    authenticationFlowType: 'USER_SRP_AUTH',
  };
}

export default getAuthConfig;
