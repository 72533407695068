import React from 'react';
import { Formik, Form, Field, FormikActions } from 'formik';
import * as yup from 'yup';
import { Typography, CardContent, Button, withStyles, Link } from '@material-ui/core';

import CardContainer from 'components/CardContainer/CardContainer';
import EmailField, { emailValidation } from 'components/FormElements/EmailField/EmailField';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import styles from './styles';
import { changeEmail, getUserEmail } from 'components/Auth/auth.service';
import AbsoluteRedirect from 'components/AbsoluteRedirect/AbsoluteRedirect';

interface ChangeEmailProps {
  classes: any;
}

export interface ChangeEmailState {
  serverError: boolean;
  redirectToAccount: boolean;
  currentEmail: string;
}

export class ChangeEmail extends React.Component<ChangeEmailProps, ChangeEmailState> {
  public state: ChangeEmailState = {
    serverError: false,
    redirectToAccount: false,
    currentEmail: '',
  };

  private schema: yup.ObjectSchema<any>;

  constructor(props: ChangeEmailProps) {
    super(props);
    this.schema = yup.object().shape({
      email: emailValidation,
    });
  }

  public componentDidMount = async () => {
    const email = await getUserEmail();
    this.setState({ currentEmail: email })
  }

  public handleSubmit = async ({ email }: any, actions: FormikActions<any>) => {
    try {
      await changeEmail(email);
      this.setState({ serverError: false, redirectToAccount: true });
    } catch (exception) {
      this.setState({ serverError: true });
    }
    actions.setSubmitting(false);
  }

  public render() {
    const { classes } = this.props;
    const { currentEmail } = this.state;
    if (this.state.redirectToAccount) {
      return <AbsoluteRedirect to="/" />;
    }

    return (
      <>
        {this.state.serverError &&
          <ErrorToast>
               We encountered a problem. Please try again, or{` `}
              <a
                href="https://knowledge.sovrn.com/kb-tickets/new"
                target="_blank"
                rel="noopener noreferrer"
                id="contact-support"
                style={{ color: 'white' }}
              >
              contact support
              </a>.
          </ErrorToast>
        }
        <CardContainer>
          <CardContent className={classes.cardContent}>
            <Typography variant="h4" className={classes.header}>Change Email</Typography>
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={{ email: '' }}
              validationSchema={this.schema}
              render={(props) => (
                <Form>
                  <Typography variant="body2" align="left">
                    Current email is {currentEmail}
                  </Typography>
                  <Field
                    type="email"
                    autoComplete="email"
                    margin="normal"
                    name="email"
                    id="email-input"
                    label="New Email"
                    component={EmailField}
                  />
                  <Button
                    id="reset-button"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={!props.isValid || props.isSubmitting}
                    className={classes.button}
                  >
                    Change Email
                  </Button>
                  <span className={classes.spanBackToMeridian}>
                    <Link className={classes.linkBackToMeridian} href={'/'}>
                      <Typography variant="body2" align="center" className={classes.backToMeridian}>
                        Back To Meridian
                      </Typography>
                    </Link>
                  </span>
                </Form>
              )}
            />
          </CardContent>
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(ChangeEmail);
