import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './Root/Root';
import TagManager from 'react-gtm-module';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import theme from 'components/theme';

interface AppProps {
  envHostname: string;
}

class App extends Component<AppProps> {
  constructor(props: AppProps) {
    super(props);

    const productionHost = 'platform.sovrn.com';
    const stagingHost = 'platform-stg.sovrn.com';
    const qaHost = 'platform-qa.sovrn.com';

    const prodGtmId = 'GTM-M43WLHL';
    const qaGtmId = 'GTM-59Q4WJW';

    let tagManagerArgs = { gtmId: qaGtmId, dataLayerName: 'PageDataLayer' };
    let env = 'dev';
    const envHostname = this.props.envHostname || 'localhost';

    if (envHostname.toLowerCase().includes(productionHost)) {
      tagManagerArgs = { gtmId: prodGtmId, dataLayerName: 'PageDataLayer' };
      env = 'prd';
    } else if (envHostname.toLowerCase().includes(stagingHost)) {
      tagManagerArgs = {
        gtmId: qaGtmId,
        dataLayerName: 'PageDataLayer',
      };
      env = 'stg';
    } else if (envHostname.toLowerCase().includes(qaHost)) {
      tagManagerArgs = {
        gtmId: qaGtmId,
        dataLayerName: 'PageDataLayer',
      };
      env = 'qa';
    }

    TagManager.initialize(tagManagerArgs);

    datadogRum.init({
      applicationId: 'e2695dea-39f0-4ff1-a001-998f5d9ed05d',
      clientToken: 'pub7e95ccac94e231973568ff901320f937',
      site: 'datadoghq.com',
      service: 'meridian-account',
      env,
      version: process.env.npm_package_version,
      sampleRate: 100,
      trackInteractions: true,
      beforeSend: (event) => {
        event.view.url = event.view.url.replace(/email=[^&]*/, "email=REDACTED");
        event.view.referrer = event.view.referrer?.replace(/email=[^&]*/, "email=REDACTED");
      }
    });

    datadogLogs.init({
      clientToken: 'pub7e95ccac94e231973568ff901320f937',
      site: 'datadoghq.com',
      service: 'meridian-account',
      env,
      version: process.env.npm_package_version,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }

  public render() {
    return (
      <>
        <ErrorBoundary>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Router basename="/account">
              <Root />
            </Router>
          </MuiThemeProvider>
        </ErrorBoundary>
      </>
    );
  }
}

export default App;
