import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
    },
    mfaField: {
      display: 'block',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 1,
    },
    containerSpan: {
      marginLeft: theme.spacing.unit * -1.5,
    },
    linkToSupport: {
      width: '100%',
      textDecoration: 'none',
    },
    spanToSupport: {
      width: '100%',
      textAlign: 'center',
      display: 'inline-flex',
    },
    toSupport: {
      color: 'rgba(40, 40, 40, 0.6)',
      letterSpacing: '0.4px',
      lineHeight: '12px',
      marginBottom: theme.spacing.unit * 2,
      cursor: 'pointer',
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });

export default styles;
