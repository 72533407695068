import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      marginTop: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
    },
    header: {
      marginBottom: theme.spacing.unit * 3,
    },
    linkBackToMeridian: {
      width: '100%',
      textDecoration: 'none',
    },
    spanBackToMeridian: {
      width: '100%',
      textAlign: 'center',
      display: 'inline-flex',
    },
    backToMeridian: {
      color: 'rgba(40, 40, 40, 0.6)',
      letterSpacing: '0.4px',
      lineHeight: '12px',
      marginBottom: theme.spacing.unit * 2,
    },
  });

export default styles;
