import * as React from 'react';
import * as yup from 'yup';

import {
  Typography,
  TextField,
  withStyles,
} from '@material-ui/core';
import { FieldProps, ErrorMessage } from 'formik';
import styles from './styles';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface EmailFieldProps
  extends FieldProps {
  name: string;
  label?: string;
  classes: { emailField: string };
}

export const emailValidation = yup.string().email().required();

const EmailField = (props: EmailFieldProps) => {
  const { classes } = props;
  return (
  <>
    <TextField
      className={classes.emailField}
      label={props.label ? props.label : 'Email'}
      {...props.field}
    />
    <ErrorMessage name={props.field.name}>
      {() => (
        <Typography id="validation-error-email-input" color="error" variant="caption">
          Please enter a valid email address
        </Typography>
      )}
    </ErrorMessage>
  </>
  );
};

export default withStyles(styles)(EmailField);
