import React from 'react';
import { Formik, Form, Field, FormikActions } from 'formik';
import * as yup from 'yup';
import { Typography, CardContent, Button, withStyles } from '@material-ui/core';

import CardContainer from 'components/CardContainer/CardContainer';
import PasswordField, { passwordValidation } from 'components/FormElements/PasswordField/PasswordField';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import styles from './styles';
import { changePassword, triggerPasswordChangedEmail } from 'components/Auth/auth.service';
import AbsoluteRedirect from 'components/AbsoluteRedirect/AbsoluteRedirect';

interface ChangePasswordProps {
  classes: any;
}

export interface ChangePasswordState {
  serverError: boolean;
  redirectToAccount: boolean;
}

export class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
  public state: ChangePasswordState = {
    serverError: false,
    redirectToAccount: false,
  };

  private schema: yup.ObjectSchema<any>;

  constructor(props: ChangePasswordProps) {
    super(props);
    this.schema = yup.object({
      oldPassword: yup.string()
        .strict(true)
        .required('Please enter a valid password'),
      newPassword: passwordValidation,
    });
  }

  public handleSubmit = async ({ oldPassword, newPassword }: any, actions: FormikActions<any>) => {
    try {
      await changePassword(oldPassword, newPassword);
      await triggerPasswordChangedEmail();
      this.setState({ serverError: false, redirectToAccount: true });
    } catch (exception) {
      this.setState({ serverError: true });
    }
    actions.setSubmitting(false);
  }

  public render() {
    const { classes } = this.props;
    if (this.state.redirectToAccount) {
      return <AbsoluteRedirect to="/#account" />;
    }

    return (
      <>
        {this.state.serverError ? (
          <ErrorToast>
               We encountered a problem. Please try again, or{` `}
              <a
                href="https://knowledge.sovrn.com/kb-tickets/new"
                target="_blank"
                rel="noopener noreferrer"
                id="contact-support"
                style={{ color: 'white' }}
              >
              contact support
              </a>.
          </ErrorToast>
        ) : null}
        <CardContainer>
          <CardContent className={classes.cardContent}>
            <Typography variant="h4" className={classes.header}>Change Password</Typography>
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={{ oldPassword: '', newPassword: '' }}
              validationSchema={this.schema}
              render={(props) => (
                <Form>
                  <Field id="oldPassword" type="password" label="Current Password" name="oldPassword" component={PasswordField} />
                  <Field id="newPassword" type="password" label="New Password" name="newPassword" component={PasswordField} />
                  <Button
                    id="change-button"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={!props.isValid || props.isSubmitting}
                    className={classes.button}
                  >
                    Change Password
                  </Button>
                </Form>
              )}
            />
          </CardContent>
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(ChangePassword);
