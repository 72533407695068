import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      color: 'grey',
    },
    input: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
    },
  });

export default styles;
