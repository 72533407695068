/* eslint-disable no-console */
import React from 'react';
import { Redirect } from 'react-router';
import { Formik, Form, Field, FormikActions } from 'formik';
import qs from 'qs';
import * as yup from 'yup';
import { Typography, CardContent, Button, withStyles } from '@material-ui/core';

import CardContainer from '../../../CardContainer/CardContainer';
import PasswordField, { passwordValidation } from '../../../FormElements/PasswordField/PasswordField';
import { ErrorToast } from '../../../ToastMessage/ToastMessage';
import styles from './styles';
import { forgotPasswordReset } from '../../../Auth/auth.service';

interface ForgotPasswordResetProps {
  classes: any;
  location: { search: any };
}

export interface ForgotPasswordResetState {
  serverError: boolean;
  confirmationForm: boolean;
}

export class ForgotPasswordReset extends React.Component<ForgotPasswordResetProps, ForgotPasswordResetState> {
  public state: ForgotPasswordResetState = {
    serverError: false,
    confirmationForm: false,
  };

  private schema: yup.ObjectSchema<any>;

  constructor(props: ForgotPasswordResetProps) {
    super(props);
    this.schema = yup.object({
      password: passwordValidation,
    });
  }

  public handleSubmit = async ({ password }: any, actions: FormikActions<any>) => {
    const { token, email } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    try {
      await forgotPasswordReset(email, token, password);
      this.setState({ serverError: false, confirmationForm: true });
    } catch (exception) {
      this.setState({ serverError: true });
    }
    actions.setSubmitting(false);
  }

  public render() {
    const { classes } = this.props;

    if (this.state.confirmationForm) {
      const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      console.log(query);
      delete query.token;
      delete query.email;
      console.log(qs.stringify(query, { addQueryPrefix: true }));
      return <Redirect to={`/confirm-changed-password${qs.stringify(query, { addQueryPrefix: true })}`} />;
    }

    return (
      <>
        {this.state.serverError ? (
          <ErrorToast>
               We encountered a problem. Please try again, or{` `}
              <a
                href="https://knowledge.sovrn.com/kb-tickets/new"
                target="_blank"
                rel="noopener noreferrer"
                id="contact-support"
                style={{ color: 'white' }}
              >
              contact support
              </a>.
          </ErrorToast>
        ) : null}
        <CardContainer>
          <CardContent className={classes.cardContent}>
            <Typography variant="h4" className={classes.header}>Password Reset</Typography>
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={{ password: '', passwordConfirm: '' }}
              validationSchema={this.schema}
              render={(props) => (
                <Form>
                  <Field id="password" type="password" label="Password" name="password" component={PasswordField} />
                  <Button
                    id="reset-button"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={!props.isValid || props.isSubmitting}
                    className={classes.button}
                  >
                    Reset Password
                  </Button>
                </Form>
              )}
            />
          </CardContent>
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(ForgotPasswordReset);
