import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
    },
    header: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
export default styles;
