import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing.unit * 2,
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
      borderRadius: theme.spacing.unit / 2,
    },
    linkForgot: {
      width: '100%',
      textDecoration: 'none',
    },
    spanForgot: {
      width: '100%',
      textAlign: 'center',
      display: 'inline-flex',
    },
    forgotPassword: {
      color: 'rgba(40, 40, 40, 0.6)',
      letterSpacing: '0.4px',
      lineHeight: '12px',
      marginBottom: theme.spacing.unit * 2,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px'
      },
    },
  });

export default styles;
