import {
  Typography,
  CardContent,
  Button,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import * as React from 'react';
import { Formik, Form, Field, FormikActions } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import styles from './styles';
import EmailField, {
  emailValidation,
} from '../../../FormElements/EmailField/EmailField';
import { persistRedirect } from 'components/AbsoluteRedirect/redirectTargets.service';

interface ForgotPasswordFormProps {
  classes?: any;
  handleSubmission: ({}: any, {}: any) => void;
  isSubmitting: boolean;
}

export class ForgotPasswordForm extends React.Component<
  ForgotPasswordFormProps
> {
  private schema: yup.ObjectSchema<any>;

  constructor(props: ForgotPasswordFormProps) {
    super(props);
    this.schema = yup.object().shape({
      email: emailValidation,
    });
  }

  public handleSubmit = (formValues: any, actions: FormikActions<any>) => {
    const { handleSubmission } = this.props;

    handleSubmission(formValues, actions);
    persistRedirect();
  };

  public render() {
    const { classes } = this.props;

    return (
      <CardContent className={classes.cardContent}>
        <Typography variant="h4" className={classes.header}>
          Forgot Password
        </Typography>
        <Formik
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
          initialValues={{ email: '' }}
          render={props => (
            <Form>
              <Field
                type="email"
                autoComplete="email"
                margin="normal"
                name="email"
                id="email-input"
                component={EmailField}
              />
              <Button
                id="reset-button"
                variant="contained"
                type="submit"
                color="secondary"
                disabled={!props.isValid || props.isSubmitting}
                className={classes.button}
              >
                {this.props.isSubmitting ? (
                  <CircularProgress size={22} color="secondary" />
                ) : (
                  `Send Reset Email`
                )}
              </Button>
              <span className={classes.spanBackToSignIn}>
                <Link
                  className={classes.linkBackToSignIn}
                  to={`/signin${window.location.search}`}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.backToSignIn}
                  >
                    Back To Sign In
                  </Typography>
                </Link>
              </span>
            </Form>
          )}
        />
      </CardContent>
    );
  }
}

export default withStyles(styles)(ForgotPasswordForm);
