import * as React from 'react';
import { withStyles, Typography, CardContent, Button } from '@material-ui/core';
import styles from './styles';
import CardContainer from '../../../CardContainer/CardContainer';
interface ConfirmForgotPasswordResetProps {
  classes?: any;
}

const ConfirmForgotPasswordReset = (props: ConfirmForgotPasswordResetProps) => {
  const { classes } = props;

  return (
    <CardContainer>
      <CardContent>
        <Typography variant="h4" className={classes.header}>Success</Typography>
        <Typography variant="subtitle1">You successfully changed your password.</Typography>
        <Button
          id="signin-button"
          variant="contained"
          href={`/account/signin${window.location.search}`}
          color="secondary"
          className={classes.button}
        >
          Return to Sign In
        </Button>
      </CardContent>
    </CardContainer>
  );
};
export default withStyles(styles)(ConfirmForgotPasswordReset);
