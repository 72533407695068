import * as React from 'react';
import { CardContent, Typography, Button, withStyles } from '@material-ui/core';
import styles from './styles';

interface Props {
  classes: any;
  title: string;
  message: string;
  disableSendEmail: boolean;
  handleSendEmail: ({}: any) => void;
}

export class Confirm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {
      classes,
      disableSendEmail,
      message,
      handleSendEmail,
      title,
    } = this.props;
    return (
      <>
        <CardContent>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body2" className={classes.message}>
            {message}
          </Typography>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            className={classes.button}
            disabled={disableSendEmail}
            onClick={handleSendEmail}
          >
            {disableSendEmail ? 'Email Sent' : 'Resend Email'}
          </Button>
        </CardContent>
      </>
    );
  }
}

export default withStyles(styles)(Confirm);
