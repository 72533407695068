import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {
  Typography,
  CardContent,
  Button,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { Formik, Form, Field, FormikActions } from 'formik';
import qs from 'qs';
import * as yup from 'yup';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';

import PasswordField from 'components/FormElements/PasswordField/PasswordField';
import styles from './styles';
import {
  hasSovrnAccount,
  login,
  LOGIN_STATUS,
  authenticateToCommerce,
  googleLogin,
  createUserSession,
  hasUserSession,
  hasCommerceSession,
  hasJWT,
  confirmMfaToken,
  createAdminSession,
  setComrefAttribute,
  createCustomer,
} from 'components/Auth/auth.service';
import {
  persistRedirect,
  getRedirectTarget,
  getTargetFromGoogleRedirect,
} from 'components/AbsoluteRedirect/redirectTargets.service';
import CardContainer from 'components/CardContainer/CardContainer';
import EmailField, {
  emailValidation,
} from 'components/FormElements/EmailField/EmailField';
import { InfoToast, ErrorToast } from 'components/ToastMessage/ToastMessage';
import GoogleButton from 'components/FormElements/GoogleButton/GoogleButton';
import CompletePassword from 'components/Signin/CompletePassword/CompletePassword';
import CompleteMfa from './CompleteMfa/CompleteMfa';

const COMMERCE_DOMAIN = 'commerce';

interface SigninProps {
  classes: any;
  location: any;
  history?: any;
}

export interface HubPayload {
  event: string;
  data?: any;
  message?: string;
}

export interface HubCapsule {
  channel: string;
  payload: HubPayload;
  source: string;
  patternInfo?: string[];
}

export interface SigninState {
  error: JSX.Element | null;
  redirectToConfirmation: boolean;
  completePasswordCognitoUser: any;
  renderCompletePassword: boolean;
  renderCompleteMfa: boolean;
  processing: boolean;
  done: boolean;
  email: string;
  user: any;
  isLoggedIn: boolean;
  path?: string;
  tempPassword?: string;
}

export const SERVER_ERROR = (
  <>
    We encountered a problem. Please try again, or{` `}
    <a
      href="https://knowledge.sovrn.com/kb-tickets/new"
      target="_blank"
      rel="noopener noreferrer"
      id="contact-support"
      style={{ color: 'white' }}
    >
      contact support
    </a>
    .
  </>
);
export const LOGIN_ERROR = (
  <>Sorry, you entered an incorrect email or password.</>
);

export const TOTP_ERROR = <>The verification code is invalid or expired.</>;

const schema = yup.object().shape({
  email: emailValidation,
  password: yup.string().required('Please enter a valid password'),
});

export class Signin extends React.Component<SigninProps, SigninState> {
  public state: SigninState = {
    error: null,
    redirectToConfirmation: false,
    completePasswordCognitoUser: {},
    renderCompletePassword: false,
    renderCompleteMfa: false,
    done: false,
    processing: false,
    email: '',
    user: null,
    isLoggedIn: false,
    path: undefined,
    tempPassword: undefined,
  };

  public signIn = async () => {
    try {
      this.setState({ processing: true });
      const hasAccount = await hasSovrnAccount();
      if (location.hostname.includes('admin')) {
        await createAdminSession();
      }
      let redirect: boolean | undefined = true;
      const redirectTarget = getRedirectTarget();

      if (!hasAccount) {
        await createCustomer();
        datadogRum.addAction('Sovrn account created');
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        await setComrefAttribute();
        await createUserSession();
      }

      persistRedirect();
      try {
        redirect = await authenticateToCommerce();
      } catch (e) {
        redirect = true;
        if (redirectTarget.domain.includes(COMMERCE_DOMAIN)) {
          return this.setState({ error: SERVER_ERROR, processing: false });
        }
      }
      if (redirect) {
        await TagManager.dataLayer({
          dataLayer: { event: 'successful_login' },
          dataLayerName: 'PageDataLayer',
        });
        this.setState({ done: true, processing: false });
      }
    } catch (exception) {
      this.setState({ error: SERVER_ERROR, processing: false });
    }
  };

  public handleSignInFailure = (errorCode: any) => {
    switch (errorCode) {
      case LOGIN_STATUS.USER_NOT_FOUND:
      case LOGIN_STATUS.NOT_AUTHORIZED:
        this.setState({ error: LOGIN_ERROR, processing: false });
        break;
      case LOGIN_STATUS.USER_NOT_CONFIRMED:
        this.setState({ redirectToConfirmation: true, processing: false });
        break;
      default:
        this.setState({ error: SERVER_ERROR, processing: false });
        break;
    }
  };

  public handleAuthEvent = async ({ payload }: HubCapsule) => {
    const { event, data } = payload;

    switch (event) {
      case 'signIn':
        this.setState({ processing: true, error: null });
        await this.signIn();
        break;
      case 'signIn_failure':
        if (
          data.message ===
          "Cannot read properties of undefined (reading 'accessToken')"
        ) {
          this.setState({ processing: true });

          try {
            await googleLogin();
          } catch (e) {
            this.handleSignInFailure(data.code);
          } finally {
            this.setState({ processing: false });
          }
        } else {
          this.handleSignInFailure(data.code);
          this.setState({ processing: false });
        }
        break;
      default:
        this.setState({ processing: false });
        break;
    }
  };

  public handleSigninDirect = async () => {
    try {
      const [vgSession, svSession, jwt] = await Promise.all([
        hasCommerceSession(),
        hasUserSession(),
        hasJWT(),
      ]);

      persistRedirect(true);
      if (this.state.path) {
        const redirect = getTargetFromGoogleRedirect(this.state.path);
        localStorage.setItem(
          'signup-redirect-target',
          JSON.stringify(redirect),
        );
      }
      this.setState({
        isLoggedIn: svSession && vgSession && jwt,
        processing: false,
      });
    } catch (error) {
      this.setState({ isLoggedIn: false, processing: false });
    }
  };

  public handleConfirmSignin = async (mfaTotp: any) => {
    try {
      persistRedirect(true);
      await confirmMfaToken(mfaTotp, this.state.completePasswordCognitoUser);
      this.setState({ error: null, isLoggedIn: true });
    } catch (e) {
      this.setState({ error: TOTP_ERROR, isLoggedIn: false });
    }
  };

  public componentDidMount = async () => {
    const str = qs.parse(window.location.search).state;
    const params = new URLSearchParams(this.props.location.search);
    if (params.get('d') == 'com' || params.get('d') == 'comq') {
      params.delete('d');
      params.delete('path');
      this.props.history.replace({ search: params.toString() });
    }
    const path = str?.substring(str.indexOf('-') + 1);
    this.setState({ path });
    Hub.listen('auth', this.handleAuthEvent);
    await this.handleSigninDirect();
  };

  public componentWillUnmount() {
    // Hub requires you to pass the exact same function object that you passed into the listen
    Hub.remove('auth', this.handleAuthEvent);
  }

  public handleSubmit = async (
    { email, password }: any,
    actions: FormikActions<any>,
  ) => {
    try {
      this.setState({ email, processing: true, error: null });
      const user = await login(email, password);

      if (user.challengeName) {
        switch (user.challengeName) {
          case LOGIN_STATUS.NEW_PASSWORD_REQUIRED:
            this.setState({
              email,
              completePasswordCognitoUser: user,
              renderCompletePassword: true,
              processing: false,
              tempPassword: password,
            });
            break;
          case LOGIN_STATUS.SOFTWARE_TOKEN_MFA:
            this.setState({
              email,
              processing: false,
              renderCompleteMfa: true,
              completePasswordCognitoUser: user,
            });
            break;

          default:
            this.setState({ error: SERVER_ERROR, processing: false });
            break;
        }
      }
    } catch (exception) {
      // Hub listener will handle this case
    } finally {
      actions.setSubmitting(false);
    }
  };

  public renderError = () => {
    if (this.state.error === null) {
      return null;
    } else {
      return <ErrorToast id="sign-in-error">{this.state.error}</ErrorToast>;
    }
  };

  public render() {
    const { classes, location } = this.props;
    const {
      redirectToConfirmation,
      renderCompletePassword,
      renderCompleteMfa,
      email,
      processing,
      done,
      isLoggedIn,
    } = this.state;

    const { session_expired: sessionExpired, ...query } = qs.parse(
      location.search,
      {
        ignoreQueryPrefix: true,
      },
    );

    if (isLoggedIn || done) {
      return <Redirect to="/signin/router/" />;
    }

    if (redirectToConfirmation) {
      const confirmQuery = { email, ...query };
      return <Redirect to={`/signup/confirm?${qs.stringify(confirmQuery)}`} />;
    }

    if (renderCompletePassword) {
      return (
        <CompletePassword
          user={this.state.completePasswordCognitoUser}
          tempPassword={this.state.tempPassword}
        />
      );
    }

    if (renderCompleteMfa) {
      return (
        <CompleteMfa
          handleConfirmSignin={this.handleConfirmSignin}
          errMsg={this.state.error}
        />
      );
    }
    return (
      <>
        {this.renderError()}
        {sessionExpired ? (
          <InfoToast id="session-expired-msg">
            Your session has expired. Please log in again.
          </InfoToast>
        ) : null}
        {processing ? (
          <InfoToast id="spinner">
            <CircularProgress size={44} thickness={4} />
          </InfoToast>
        ) : null}
        <CardContainer>
          <CardContent className={classes.cardContent}>
            <Typography variant="h4" align="center" id="welcome-back-title">
              Welcome Back
            </Typography>
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={{ email: '', password: '' }}
              validationSchema={schema}
              render={({ isSubmitting, isValid }) => (
                <Form>
                  <GoogleButton />
                  <Field
                    type="email"
                    validateOnBlur={true}
                    autoComplete="email"
                    label="Email"
                    margin="normal"
                    name="email"
                    id="email"
                    component={EmailField}
                  />
                  <Field
                    id="password"
                    label="Password"
                    margin="none"
                    name="password"
                    component={PasswordField}
                  />
                  <Button
                    id="login-button"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={processing || !isValid || isSubmitting}
                    className={classes.button}
                  >
                    Log In
                  </Button>
                  <span className={classes.spanForgot}>
                    <Link
                      className={classes.linkForgot}
                      to={`/forgot-password${location.search}`}
                    >
                      <Typography
                        variant="body2"
                        align="center"
                        className={classes.forgotPassword}
                      >
                        Forgot Password?
                      </Typography>
                    </Link>
                  </span>
                </Form>
              )}
            />
          </CardContent>
        </CardContainer>
      </>
    );
  }
}

export default withStyles(styles)(Signin);
