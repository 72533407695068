import * as React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { FormikValues } from 'formik';
import styles from './styles';
import * as yup from 'yup';

export const schemaMfa = yup.object().shape({
  mfa: yup
    .string()
    .strict(true)
    .matches(/^[0-9]*$/)
    .min(6, 'too short')
    .max(6)
    .required(),
});

export const MfaField: React.ComponentType<FormikValues> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { classes } = props;

  return (
    <span className={classes.root}>
      <TextField {...field} {...props}>
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name]}</div>
        )}
      </TextField>
    </span>
  );
};

export default withStyles(styles)(MfaField);
