import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    message: {
      width: '100%',
      paddingBottom: '20px',
      paddingTop: '20px',
    },
    button: {
      width: '100%',
      marginBottom: theme.spacing.unit * 3,
      textTransform: 'none',
    },
    whiteLink: {
      color: 'white',
      textDecoration: 'none',
    },
    link: {
      textDecoration: 'none',
    },
  });

export default styles;
