import { createStyles } from '@material-ui/core';
const styles = () =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',
    },
  });

export default styles;
