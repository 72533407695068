import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { datadogRum } from '@datadog/browser-rum';

import { persistRedirect } from 'components/AbsoluteRedirect/redirectTargets.service';

export enum LOGIN_STATUS {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP = 'MFA_SETUP',
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
  TOKEN_EXCHANGE_FAILED = 'TOKEN_EXCHANGE_FAILED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  COMMERCE_LOGIN_FAILED = 'COMMERCE_LOGIN_FAILED',
  COMMERCE_LOGIN_ERROR = ' COMMERCE_LOGIN_ERROR',
}

export enum SIGNUP_STATUS {
  USERNAME_EXISTS = 'UsernameExistsException',
}

const cleanQueryParams = (queryParams: string): string => {
  if (queryParams && queryParams.length > 0 && queryParams[0] === '?') {
    return queryParams.substr(1);
  }

  return queryParams;
};

const getComrefAttributeFromParams = () => {
  const queryParams = cleanQueryParams(window.location.search);
  const comref = new URLSearchParams(queryParams).get('comref');

  return comref && { 'custom:comref': comref };
};

const persistComref = () => {
  const comref = getComrefAttributeFromParams();
  if (comref) {
    localStorage.setItem('comref', comref['custom:comref']);
  }
};

export const cognitoLogin = async (email: string, password: string) => {
  return Auth.signIn(email?.toLowerCase(), password.trim());
};

export const cognitoGoogleLogin = async () => {
  persistRedirect();
  persistComref();

  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: window.location.search.substr(1).toString(),
  });
};

export const setComrefAttribute = async () => {
  const comref = localStorage.getItem('comref');

  if (comref) {
    const user = await Auth.currentAuthenticatedUser();
    localStorage.removeItem('comref');

    return Auth.updateUserAttributes(
      user,
      { 'custom:comref': comref },
      {
        origin: window.location.origin.toLowerCase(),
        queryParams: cleanQueryParams(window.location.search),
      },
    );
  }

  return;
};

export const register = async (email: string, password: string) => {
  const lowerCaseEmail = email?.toLowerCase();
  const comref = getComrefAttributeFromParams();

  return Auth.signUp({
    username: lowerCaseEmail,
    password: password.trim(),
    attributes: {
      email: lowerCaseEmail,
      ...(comref && { ...comref }),
    },
    clientMetadata: {
      origin: window.location.origin.toLowerCase(),
      queryParams: cleanQueryParams(window.location.search),
    },
  });
};

export const confirmRegistration = async (email: string, token: string) => {
  return Auth.confirmSignUp(email?.toLowerCase(), token);
};

export const resendRegistrationConfirmation = async (email: string) => {
  datadogRum.addAction('resend confirmation email');
  return Auth.resendSignUp(email?.toLowerCase(), {
    origin: window.location.origin.toLowerCase(),
    queryParams: cleanQueryParams(window.location.search),
  });
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, oldPassword.trim(), newPassword.trim());
};

export const forgotPassword = async (email: string) => {
  return Auth.forgotPassword(email?.toLowerCase(), {
    origin: window.location.origin.toLowerCase(),
    queryParams: cleanQueryParams(window.location.search),
  });
};

export const forgotPasswordReset = async (
  email: string,
  token: string,
  newPassword: string,
) => {
  return Auth.forgotPasswordSubmit(
    email?.toLowerCase(),
    token,
    newPassword.trim(),
  );
};

export const completeNewPassword = async (user: any, password: string) => {
  return await Auth.completeNewPassword(user, password, undefined, {
    origin: window.location.origin.toLowerCase(),
    queryParams: cleanQueryParams(window.location.search),
  });
};

export const getJwtAuthorizationHeader = async () => {
  const session = await Auth.currentSession();
  return { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` };
};

export const getIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken();
};

export const getJWT = async () => {
  const idToken = await getIdToken();
  return idToken.getJwtToken();
};

export const hasJWT = async () => {
  try {
    const session = await Auth.currentSession();
    return Promise.resolve(session !== undefined);
  } catch (e) {
    return Promise.resolve(false);
  }
};

export const confirmChangedEmail = async (token: string) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.verifyUserAttributeSubmit(user, 'email', token);
};

export const changeEmail = async (email: string) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(
    user,
    { email: email?.toLowerCase() },
    {
      origin: window.location.origin.toLowerCase(),
      queryParams: cleanQueryParams(window.location.search),
    },
  );
};

export const cognitoLogout = async () => {
  try {
    await Auth.signOut({ global: true });
    return Promise.resolve(true);
  } catch (e) {
    return Promise.resolve(false);
  }
};

export const getUserEmail = async () => {
  const user = await Auth.currentSession();
  return user.getIdToken()?.payload?.email;
};

export const confirmMfaToken = (values: any, user: any) => {
  return Auth.confirmSignIn(user, values.mfa, 'SOFTWARE_TOKEN_MFA');
};

export const cognitoJumpCloudLogin = async () => {
  persistRedirect();
  return Auth.federatedSignIn({
    customProvider: 'JumpCloudSSO',
    customState: window.location.search.substr(1).toString(),
  });
};
