import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import qs from 'qs';
import Amplify from '@aws-amplify/core';
import { withStyles, withWidth } from '@material-ui/core'

import authConfig from '../config/aws-exports';
import BackgroundLogo from '../../assets/background.svg';
import Footer from '../Footer/Footer';
import ForgotPassword from '../Password/ForgotPassword/ForgotPassword';
import ForgotPasswordReset from '../Password/ForgotPassword/ForgotPasswordReset/ForgotPasswordReset';
import ConfirmForgotPasswordReset from '../Password/ForgotPassword/ConfirmForgotPasswordReset/ConfirmForgotPasswordReset';
import Header from '../Header/Header';
import Signin from '../Signin/Signin';
import SendSignupConfirmation from '../Signup/Confirm/ConfirmationEmail';
import SignupVerification from '../Signup/Confirm/SignupVerification';
import EmailChangeVerification from '../ChangeEmail/ChangeEmailVerification/ChangeEmailVerification';
import Signup from '../Signup/Signup';
import styles from './styles';
import ChangePassword from 'components/Password/ChangePassword/ChangePassword';
import Logout from 'components/Logout/Logout'
import SigninRouter from 'components/Signin/SigninRouter'
import { ChangeEmail } from 'components/ChangeEmail/ChangeEmail';
import Banner from 'components/Banner/Banner';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import SSO from "components/Admin/SSO";


interface Props extends RouteComponentProps {
  classes?: any;
  width?: Breakpoint;
}

Amplify.configure(authConfig(window));

class Root extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  private hasHiddenBanner(location: any) {
    const { width: breakpoint } = this.props;
    const isMobileView = breakpoint !== 'lg' && breakpoint !== 'xl';
    const path = location?.pathname;
    const { d: origin } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    return path !== '/account/signin' && path !== '/account/signup' || isMobileView || origin !== 'com' && origin !== 'comq';
  }

  private createBackground() {
    const { width: breakpoint } = this.props;

    const isMobileView = breakpoint !== 'lg' && breakpoint !== 'xl';
    if (isMobileView || this.props.location?.pathname.includes("admin")) {
      return <div className={this.props.classes.backgroundColor} />;
    }

    const { d: origin } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    switch (origin) {
      case 'com':
      case 'comq':
        return <div className={this.props.classes.backgroundColor} />;
      default:
        return <BackgroundLogo className={this.props.classes.backgroundImg} />;
    }
  }

  public render() {
    const { classes } = this.props;

    return (
      <>
        {this.createBackground()}
        <Header location={location} />
        <div className={classes.bodyWrapper}>
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              <Switch>
                <>
                  <span className={classes.flexContainer}>
                    <Banner
                      location={location}
                      hidden={this.hasHiddenBanner(location)}
                    />
                    <Route path="/signin" component={Signin} exact />
                    <Route path="/signin/router" component={SigninRouter} />
                    <Route path="/signup" component={Signup} exact />
                    <Route
                      path="/signup/confirm"
                      component={SendSignupConfirmation}
                    />
                    <Route
                      path="/signup/verify"
                      component={SignupVerification}
                    />
                    <Route path="/change-email" component={ChangeEmail} />
                    <Route
                      path="/confirm-changed-email"
                      component={EmailChangeVerification}
                    />
                    <Route
                      path="/confirm-changed-password"
                      component={ConfirmForgotPasswordReset}
                    />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route
                      path="/reset-password"
                      component={ForgotPasswordReset}
                    />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/logout" component={Logout} />
                    <Route path={"/admin/sso"} exact component={SSO} />
                    <Route path="/" exact>
                      <Redirect to="/signin" />
                    </Route>
                  </span>
                </>
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default withWidth()(withStyles(styles)(withRouter(Root)));
