import * as React from 'react';
import {
  getRedirectTarget,
  clearPersistedRedirect,
} from 'components/AbsoluteRedirect/redirectTargets.service';
import AbsoluteRedirect from 'components/AbsoluteRedirect/AbsoluteRedirect';
import { hasSovrnAccount, logout } from 'components/Auth/auth.service';
import { CircularProgress } from '@material-ui/core';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import { datadogLogs } from '@datadog/browser-logs';

interface State {
  error: string;
  redirectToLocation: string;
}

export default class SigninRouter extends React.Component<
  Record<string, unknown>,
  State
> {
  state = {
    error: '',
    redirectToLocation: '',
  };

  async routeUser() {
    const redirect = getRedirectTarget();
    clearPersistedRedirect();
    await Promise.resolve(null); //anyone want to tell me why this is required here?

    try {
      const hasAccount = await hasSovrnAccount();
      if (hasAccount) {
        return this.setState({
          redirectToLocation: redirect.fqdn,
        });
      } else {
        return this.setState({ error: 'Sovrn Account not found' });
      }
    } catch (exception) {
      datadogLogs.logger.error('Error when creating the user session');
      await logout();
      return this.setState({
        redirectToLocation: `${window.location.origin}/account/signin`,
      });
    }
  }

  public componentDidMount = async () => {
    await this.routeUser();
  };

  render() {
    const { redirectToLocation, error } = this.state;

    if (error) {
      return (
        <ErrorToast id="sign-in-error">
          We encountered a problem. Please try again, or{` `}
          <a
            href="https://knowledge.sovrn.com/kb-tickets/new"
            target="_blank"
            rel="noopener noreferrer"
            id="contact-support"
            style={{ color: 'white' }}
          >
            contact support
          </a>
          .
        </ErrorToast>
      );
    }

    if (redirectToLocation) {
      return <AbsoluteRedirect to={redirectToLocation} />;
    }
    return (
      <div>
        <CircularProgress size={44} thickness={4} />
      </div>
    );
  }
}
