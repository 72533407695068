import { createStyles, Theme } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    typography: {
      color: 'rgba(40, 40, 40, 0.87)',
      textAlign: 'left',
      paddingBottom: theme.spacing.unit * 2,
      letterSpacing: '0.25px',
    },
    boldText: {
      fontWeight: 'bold',
      marginRight: theme.spacing.unit / 2,
    }
  });

export default styles;
