import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { logout, logoutCommerce } from 'components/Auth/auth.service';
import { ErrorToast } from 'components/ToastMessage/ToastMessage';
import { CircularProgress } from '@material-ui/core';

interface State {
  status: 'started' | 'error' | 'done';
}

export default class Logout extends React.Component<Record<string, unknown>, State> {
  constructor(props: any) {
    super(props);
    this.state = { status: 'started' };
  }

  async handleLogout() {
    try {
      await logout();
      await logoutCommerce();
      this.setState({ status: 'done' });
    } catch (e) {
      this.setState({ status: 'error' });
    }
  }
  componentDidMount() {
    this.handleLogout();
  }

  render() {
    if (this.state.status === 'error') {
      return (
        <ErrorToast id="sign-out-error">
          An Error Occurred During Logout
        </ErrorToast>
      );
    }
    if (this.state.status === 'done' && location.href.includes('admin')) {
      return <Redirect to={`/admin/sso`} />;
    }
    if (this.state.status === 'done') {
      return <Redirect to={`/signin${window.location.search}`} />;
    }
    return (
      <div>
        <CircularProgress size={44} thickness={4} />
      </div>
    );
  }
}
