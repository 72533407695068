import React from 'react';

interface AbsoluteRedirectProps {
  to: string;
}

class AbsoluteRedirect extends React.Component<AbsoluteRedirectProps, any> {

  public componentDidMount() {
    window.location.href = this.props.to;
  }

  public render() {
    return null;
  }

}

export default AbsoluteRedirect;
