import * as React from 'react';

import { confirmRegistration } from '../../Auth/auth.service';
import Verification from '../../Verification/Verification';
import qs from 'qs';
import { datadogLogs } from '@datadog/browser-logs';

interface Props {
  location: any;
}

const SignupVerification = (props: Props) => {
  const handler = async (location: any) => {
    try {
      const { email, token } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      await confirmRegistration(email, token);
    } catch (error: any) {
      if (
        error.message ===
        'User cannot be confirmed. Current status is CONFIRMED'
      ) {
        return datadogLogs.logger.info(
          `${error.message}. User was redirected to sign in.`,
        );
      }
      datadogLogs.logger.error(
        `Error when confirming user email: ${error.message}`,
      );
      throw error;
    }
  };

  return <Verification {...props} handler={handler} />;
};

export default SignupVerification;
