/* eslint-disable no-console */
import Auth from '@aws-amplify/auth';
import { getJwtAuthorizationHeader } from './auth-cognito.service';
import { createUserSession } from './auth-session.service';
import { http } from './http';

export const createCustomer = async () => {
  const session = await Auth.currentSession();
  const jwt = session.getIdToken().getJwtToken();
  const options = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  const response = await http.post('/api/rhea/customers', options);
  if (response.status !== 204) {
    throw new Error('Customer Creation failed.');
  }
};
export const hasSovrnAccount = async (): Promise<boolean> => {
  const response = await createUserSession();
  switch (response.status) {
    case 204:
      return true;
    case 400:
      return false;
    default:
      return Promise.reject({ code: 'UnexpectedResponse' });
  }
};

export const triggerPasswordChangedEmail = async (): Promise<void> => {
  const authHeader = await getJwtAuthorizationHeader();
  const options = {
    headers: { ...authHeader },
  };

  await http.post('/api/rhea/user/password-changed', options);
  return;
};
