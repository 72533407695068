import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { CardContent, withStyles } from '@material-ui/core';

import CardContainer from '../CardContainer/CardContainer';
import VerificationStatus from '../Verification/VerificationStatus';
import styles from './styles';
import qs from 'qs';

interface Props {
  classes: any;
  location: any;
  handler: (location: any) => void;
}

interface State {
  redirect: boolean;
  confirmed: boolean;
  checking: boolean;
}

export class Verification extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      redirect: false,
      confirmed: false,
      checking: true,
    };
  }

  public async componentDidMount() {
    try {
      await this.props.handler(this.props.location);
      this.setState({ confirmed: true, checking: false });
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 6000);
    } catch (exception) {
      this.setState({ confirmed: false, checking: false });
    }
  }

  public render() {
    const { classes, location } = this.props;

    if (this.state.redirect) {
      const { token: _token, email: _email, ...query } = qs.parse(location.search, { ignoreQueryPrefix: true });
      const queryStr = qs.stringify(query);
      return <Redirect to={`/signin${queryStr ? `?${queryStr}` : ''}`} />;
    }

    return (
      <CardContainer>
        <CardContent className={classes.cardContent}>
          <VerificationStatus location={location} {...this.state} />
        </CardContent>
      </CardContainer>
    );
  };
};

export default withStyles(styles)(Verification);
