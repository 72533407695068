import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import qs from 'qs';
import { AppBar, Toolbar, Button, withStyles, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

import styles from './styles';
import SovrnLogo from '../../assets/sovrn-type.svg';
import SovrnCommerceLogo from '../../assets/sovrn-commerce-type.svg';

interface Props {
  location: any;
  hidden?: boolean;
  classes?: any;
  width?: Breakpoint;
}

const createHeaderLogo = (className: string, breakpoint?: Breakpoint, origin?: string) => {
  let Logo = SovrnLogo;
  let href = 'https://sovrn.com/';

  const isDesktopView = breakpoint === 'lg' || breakpoint === 'xl';
  if (isDesktopView) {
    switch (origin) {
      case 'com':
      case 'comq':
        Logo = SovrnCommerceLogo;
        href = 'https://www.sovrn.com/publishers/commerce';
        break;
    }
  }

  return (
    <a
      id="sovrn-logo"
      className={className}
      href={href}
      target="_blank"
      rel="noopener"
    >
      <Logo />
    </a>
  )
}

const Header = ({ hidden = false, classes, width: breakpoint, location }: Props) => {
  const { d: origin } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const headerLogo = createHeaderLogo(classes.logo, breakpoint, origin);
  const { email: _email, ...query } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const queryStr = qs.stringify(query);

  const isAdminAuth = location?.pathname.includes("admin")

  return (
    <AppBar position="static" color="default" style={{ display: hidden ? 'none' : 'normal', backgroundColor: isAdminAuth && '#f7f7f7' }}>
      <Toolbar className={classes.toolbar}>
        {headerLogo}
        <Switch>
          <Route path="/signup">
            <Button color="secondary">
              <Link to={`/signin${location.search}`} id="sign-in-button" className={classes.link}>
                Sign In
              </Link>
            </Button>
          </Route>
          <Route path="/signin">
            <Button color="secondary">
              <Link to={`/signup${queryStr ? `?${queryStr}` : ''}`} id="get-started-button" className={classes.link}>
                Sign Up
              </Link>
            </Button>
          </Route>
          <Route path="/confirm-reset">
            <Button color="secondary">
              <Link to={`/signin${location.search}`} id="confirm-password-reset-button" className={classes.link}>
                Sign In
              </Link>
            </Button>
          </Route>
          <Route path="/forgot-password">
            <Button color="secondary">
              <Link to={`/signin${location.search}`} id="forgot-password-button" className={classes.link}>
                Sign In
              </Link>
            </Button>
          </Route>
          <Route path="/reset-password">
            <Button color="secondary">
              <Link to={`/signin${location.search}`} id="reset-password-button" className={classes.link}>
                Sign In
              </Link>
            </Button>
          </Route>
        </Switch>
      </Toolbar>
    </AppBar>
  );
}

export default withWidth()(withStyles(styles)(Header));
